import { node, oneOfType, string } from "prop-types";

import classes from "./FormInputErrorMessage.module.scss";

/**
 * @typedef {Object} FieldError
 * @property {string} message - The error message
 */

/**
 * @typedef {Object} FormInputErrorMessageProps
 * @property {string} htmlFor - The id of the input field
 * @property {string | FieldError} children - The error message
 */

const FormInputErrorMessage = ({ htmlFor, children }) => (
  <div id={`${htmlFor}-error`} className={classes.FormInputErrorMessage}>
    {children}
  </div>
);

FormInputErrorMessage.propTypes = {
  htmlFor: string.isRequired,
  children: oneOfType([string, node]).isRequired,
};

export default FormInputErrorMessage;
