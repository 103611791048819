import { oneOf, string } from "prop-types";

import classnames from "~utils/classnames";

import classes from "./SpinningLoader.module.scss";

const SpinningLoader = ({ message, variant = "secondary", size = "large" }) => (
  <div className={classes.SpinningLoader}>
    {message && <h4>{message}</h4>}
    <div
      className={classnames(
        classes.loader,
        classes[`loader__${variant}`],
        classes[`loader__${size}`],
      )}
    >
      <span />
    </div>
  </div>
);

SpinningLoader.propTypes = {
  message: string,
  variant: oneOf(["primary", "secondary", "black", "white"]),
  size: oneOf(["small", "medium", "large"]),
};

export default SpinningLoader;
