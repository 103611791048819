import { bool, node, oneOf, string } from "prop-types";

import Icon from "~components/Icon";
import classnames from "~utils/classnames";

import classes from "./ErrorMessage.module.scss";

export const ErrorMessageVariants = {
  BRAND: "brand",
  BLACK: "black",
};

const ToastWrapper = ({ children }) => (
  <div className={classes.toastWrapper}>{children}</div>
);

ToastWrapper.propTypes = {
  children: node.isRequired,
};

const ErrorMessage = ({
  isToast = false,
  message,
  centered = false,
  variant = ErrorMessageVariants.BRAND,
}) => {
  const component = (
    <div
      className={classnames(
        classes.ErrorMessage,
        classes[`ErrorMessage_${variant}`],
        centered && classes.ErrorMessage_centered,
      )}
    >
      <Icon type="info" />
      <span>{message}</span>
    </div>
  );
  if (isToast) {
    return <ToastWrapper>{component}</ToastWrapper>;
  }
  return component;
};

export const propTypes = {
  message: string.isRequired,
  variant: oneOf(Object.values(ErrorMessageVariants)),
  centered: bool,
  isToast: bool,
};

ErrorMessage.propTypes = propTypes;

export default ErrorMessage;
