import { bool, func, number, oneOf, oneOfType, string } from "prop-types";
import React from "react";

import classnames from "~utils/classnames";

import FormInputErrorMessage from "../FormInputErrorMessage";
import classes from "./TextInput.module.scss";

/**
 * @typedef {Object} TextInputProps
 * @property {string} id - The id of the input
 * @property {string} [label] - The label for the input
 * @property {string} [placeholder] - The placeholder text for the input
 * @property {string} [defaultValue] - The default value of the input
 * @property {string} [value] - The value of the input
 * @property {'text' | 'email' | 'search' | 'date'} [type] - The type of the input
 * @property {string | number} [min] - The minimum value for the input
 * @property {string | number} [max] - The maximum value for the input
 * @property {boolean} [hasError] - Whether the input has an error
 * @property {string} [errorMessage] - The error message to display
 * @property {(event: React.ChangeEvent<HTMLInputElement>) => void} onChange - The change handler
 * @property {(event: React.FocusEvent<HTMLInputElement>) => void} [onBlur] - The blur handler
 * @property {string} [name] - The name of the input
 * @property {boolean} [required] - Whether the input is required
 */

/**
 * TextInput Component
 * @type {React.ForwardRefExoticComponent<React.PropsWithoutRef<TextInputProps> & React.RefAttributes<HTMLInputElement>>}
 */

const TextInput = React.forwardRef(
  (
    {
      id,
      type = "text",
      placeholder,
      label = "",
      value,
      min,
      max,
      defaultValue,
      hasError = false,
      errorMessage,
      onChange,
      onBlur,
      name,
      required = false,
    },
    ref,
  ) => (
    <div
      className={classnames(classes.TextInput, hasError && classes.hasError)}
    >
      {label && <label htmlFor={id}>{label}</label>}
      <input
        ref={ref}
        id={id}
        name={name}
        type={type}
        value={value}
        min={min}
        max={max}
        defaultValue={defaultValue}
        placeholder={placeholder}
        onChange={onChange}
        onBlur={onBlur}
        required={required}
        aria-invalid={hasError ? "true" : "false"}
        aria-describedby={hasError ? `${id}-error` : null}
      />
      {hasError && errorMessage && (
        <FormInputErrorMessage htmlFor={id}>
          {errorMessage}
        </FormInputErrorMessage>
      )}
    </div>
  ),
);

TextInput.propTypes = {
  id: string.isRequired,
  label: string,
  placeholder: string,
  defaultValue: string,
  value: string,
  type: oneOf(["text", "email", "search", "date"]),
  min: oneOfType([string, number]),
  max: oneOfType([string, number]),
  hasError: bool,
  errorMessage: string,
  onChange: func,
  onBlur: func,
  name: string,
  required: bool,
};

TextInput.displayName = "TextInput";

export default TextInput;
