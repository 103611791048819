import { arrayOf, bool, func, oneOf, shape, string } from "prop-types";
import { forwardRef } from "react";

import classnames from "~utils/classnames";

import FormInputErrorMessage from "../FormInputErrorMessage";
import classes from "./Select.module.scss";

/**
 * @typedef {Object} SelectOption
 * @property {string} value - The value of the option
 * @property {string} label - The label of the option
 */

/**
 * @typedef {Object} SelectProps
 * @property {string} id - The id of the select
 * @property {string} name - The name of the select
 * @property {string} [label] - The label of the select
 * @property {boolean} [hideLabel] - Whether to hide the label
 * @property {boolean} [hasError] - Whether the select has an error
 * @property {string} [errorMessage] - The error message to display
 * @property {SelectOption[]} options - The options for the select
 * @property {string} [value] - The value of the selected option
 * @property {string} [placeholder] - The placeholder text for the select
 * @property {(event: React.ChangeEvent<HTMLSelectElement>) => void} [onChange] - The change handler
 * @property {(event: React.FocusEvent<HTMLSelectElement>) => void} [onBlur] - The blur handler
 * @property {boolean} [disabled] - Whether the select is disabled
 * @property {boolean} [required] - Whether the select is required
 * @property {string} [defaultValue] - The default value of the select
 * @property {'light' | 'dark' | 'text'} [variant] - The variant of the select
 * @property {'auto' | 'full'} [width] - The width of the select
 */

/**
 * Select Component
 * @type {React.ForwardRefExoticComponent<React.PropsWithoutRef<SelectProps> & React.RefAttributes<HTMLSelectElement>>}
 */

const Select = forwardRef(
  (
    {
      id,
      name,
      label,
      hideLabel = false,
      options,
      value,
      placeholder,
      onChange,
      onBlur,
      disabled = false,
      required = false,
      defaultValue,
      hasError,
      errorMessage,
      variant = "light",
      width = "full",
    },
    ref,
  ) => {
    const handleOnChange = (event) => {
      onChange?.(event);
      event.target.blur();
    };

    const handleKeyDown = (event) => {
      if (event.key === "Enter" || event.key === " ") {
        event.target.showPicker();
      }
    };

    return (
      <div
        className={classnames(
          classes.Select,
          variant !== "light" && classes[`Select__${variant}`],
          !value && classes.Select__placeholder,
          classes[`Select__${width}`],
        )}
      >
        {label && !hideLabel && (
          <label htmlFor={id} id={`${id}-label`}>
            {label}
          </label>
        )}
        <select
          aria-labelledby={hideLabel ? `${id}-label` : undefined}
          ref={ref}
          id={id}
          name={name}
          onChange={handleOnChange}
          onBlur={onBlur}
          disabled={disabled}
          required={required}
          value={value}
          onKeyDown={handleKeyDown}
          defaultValue={defaultValue}
          aria-invalid={hasError ? "true" : undefined}
        >
          {placeholder && <option value="">{placeholder}</option>}
          {options.map((option) => (
            <option key={option.value} value={option.value}>
              {option.label}
            </option>
          ))}
        </select>
        {hasError && errorMessage && (
          <FormInputErrorMessage htmlFor={id}>
            {errorMessage}
          </FormInputErrorMessage>
        )}
      </div>
    );
  },
);

Select.propTypes = {
  id: string.isRequired,
  name: string.isRequired,
  label: string,
  hideLabel: bool,
  hasError: bool,
  errorMessage: string,
  // @ts-ignore
  options: arrayOf(
    shape({
      value: string.isRequired,
      label: string.isRequired,
    }),
  ).isRequired,
  value: string,
  placeholder: string,
  onChange: func,
  onBlur: func,
  disabled: bool,
  required: bool,
  defaultValue: string,
  variant: oneOf(["light", "dark", "text"]),
  width: oneOf(["auto", "full"]),
};

Select.displayName = "Select";

export default Select;
